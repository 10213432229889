import React from 'react';
import { config, useSpring } from 'react-spring';
import styled from 'styled-components';
import Layout from '../components/layout';
import { AnimatedBox } from '../elements';
import SEO from '../components/SEO';

const Form = styled.form`
  /* color: palevioletred; */
  display: block;
  margin-top: 50px;
  width: 100%;
  max-width: 450px;
`;
const FormGroup = styled.div`
  /* color: palevioletred; */
  /* display: block; */
  margin-bottom: 25px;
  /* width: 100%; */
  /* max-width: 450px; */
`;

const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  /* color: palevioletred; */
`;

const Input = styled.input`
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  border: #000 1px solid;
  /* border-radius: 3px; */
  /* background: #fafafa; */
  padding: 0.5em;
  width: 100%;
  /* color: palevioletred; */
`;

const Textarea = styled.textarea`
  display: block;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  border: #000 1px solid;
  padding: 0.5em;
  width: 100%;
  height: 150px;
`;
const Button = styled.button`
  display: block;
  box-sizing: border-box;
  /* margin-bottom: 10px; */
  border: 0;
  background-color: #f00;
  padding: 0.5em;
  color: #fff;
`;

const Anchor = styled.a`
  color: #f00;
`;

const About = () => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  });

  return (
    <Layout color="#fafafa">
      <SEO title="Sobre | Agência LAVA" desc="A Agência LAVA é especializada em Web Design e Web Development" />
      <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
        <h1>Agência LAVA</h1>
        <p>
          Somos especializados em Consultoria Digital, Desenvolvimento Web (WebDev), Desenvolvimento de Aplicativos de
          Smartphone (AppDev) e Marketing Digital. Provemos serviços para empresas no Brasil, Reino Unido e Estados
          Unidos.
        </p>
        <p>
          Contamos com um time experiente que já realizou trabalhos para grandes marcas como: FIFA, Bayer,
          ArcelorMittal, Casillero del Diablo, Gallo Family, Barefoot, Cinzano, e Honda.
        </p>
        <p>
          Tem algum projeto em mente? Entre em contato pelo nosso email{' '}
          <Anchor href="mailto:hello@agencialava.com.br">hello@agencialava.com.br</Anchor>
        </p>
        {/* <Form name="contact" method="post" netlify-honeypot="bot-field" data-netlify="true">
          <FormGroup>
            <input type="hidden" name="form-name" value="contact" />
            <Input type="hidden" name="bot-field" />
            <Label htmlFor="label">Nome</Label>
            <Input name="nome" id="label" placeholder="Digite seu nome completo" required />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input name="email" type="email" placeholder="Digite seu email para contato" required />
          </FormGroup>
          <FormGroup>
            <Label>Messagem</Label>
            <Textarea name="mensagem" placeholder="Digite sua mensagem" />
          </FormGroup>
          <FormGroup>
            <Button type="submit">Enviar</Button>
          </FormGroup>
        </Form> */}
      </AnimatedBox>
    </Layout>
  );
};

export default About;
